<script>
// Extensions
import View from "@/views/public/View";

// Mixins
import LoadSections from "@/mixins/load-public-sections";
import { i18n } from "@/plugins/i18n";

export default {
  name: "OrdersDetails",

  metaInfo: { title: i18n.t("orderDetails") },

  extends: View,

  mixins: [LoadSections(["orderDetails"])],
};
</script>
